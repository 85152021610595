.domains {
background-color: #fff;
padding: 10px;
border-radius: 5px;
}

.domain {
padding-left: 10px;
padding-right: 10px;
padding-top: 3px;
padding-bottom: 3px;
    border:1px solid rgba(0,0,0,0.125);
    border-radius: 2px;
    width: 100%;

}

 ul{
    list-style: none;
}
.infos {
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 5px;
    }
    
    .info {
        text-align: center;
        padding: 10px;        
        border-top:1px solid rgba(0,0,0,0.125);
        border-radius: 2px;
       
    }