.pagination {
    margin: 4px auto;
    margin-left: 0px;
    display: flex;
    list-style: none;
    outline: none;
    
  }
  .pagination > .active > a{
    background-color: #047edf ;
    border-color: #047edf ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #047edf ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #047edf ;
    border-color: #047edf;
    outline: none ;
    
  }
  .pagination > li > a, .pagination > li > span{
    color: #047edf;
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset;
  }

  .textIn {
    border: 1px solid #047edf ;
    border-radius: 2px;
    margin-right: 0px;
  }

  .textIn :focus {
    border: 1px solid #047edf ;
   
  }


  input[type="text"]{
    border: 1px solid grey;
    border-radius: 3px;
  }


  .select{
    border: 1px solid grey !important;
    border-radius: 3px !important;
  }

  .lb {
    font-size: 0.9em;
    margin-top: 0.5rem;
    margin-bottom: 0;

  }

  .orderComp{

    padding-top: 50px !important;
  }