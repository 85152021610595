.ac {
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    top: 40%;
    width: 6%; /* Full width */
 
  }
  
  /* Modal Content */
  .ac-content {
    margin: auto;
    width: 95%;
  }
  
  /* The Close Button */
  .close {
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

